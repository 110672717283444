<template>
  <div class="about">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <h2 class="h2 margin">
            <!-- <h5 class="h5">
              Detalle de la noticia
            </h5> -->
            Detalle de la noticia
          </h2>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/noticia" class="link breadcrumb">
            Noticias
          </router-link>
          <div class="slash">/</div>
          <div class="breadcrumb-text">
            Detalle
          </div>
        </div>
        <div class="post-container">
          <h3 class="h3">{{ noticia.titulo }}</h3>
          <div class="inline-text">
            <b>Publicado por: </b>DGFM, en {{ noticia.fecha | formatdate }}
            <p>
              <b>Tipo de contenido: </b>
              {{
                noticia.tipo_noticium ? noticia.tipo_noticium.descripcion : ""
              }}
            </p>
          </div>
          <div class="rich-text w-richtext">
            <p>
              {{ noticia.descripcion }}
            </p>
            <figure
              class="w-richtext-align-center w-richtext-figure-type-image"
            >
              <div v-if="noticia.imagen">
                <img
                  :src="`${serve}/noticias/${noticia.imagen}`"
                  loading="lazy"
                  alt="Noticia"
                />
              </div>
              <figcaption class="text">
                Dirección General de Formación de Maestros
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import axios from "axios";
export default {
  name: "news_detail",
  data() {
    return {
      loading: false,
      noticia_id: "",
      noticia: {
        titulo: "",
        descripcion: "",
        imagen: "",
        direccion: "",
      },
      serve: "",
    };
  },
  mounted() {
    this.serve = Service.getServe();
    this.noticia_id = Service.getNewsId();
    if (this.noticia_id) {
      this.getNoticia(this.noticia_id);
    } else {
      this.$router.push({ name: "noticia" });
    }
  },
  methods: {
    getNoticia(noticia_id) {
      this.loading = true;
      axios
        .post(
          Service.getBase() + "noticia/detalle",
          { noticia_id: noticia_id },
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.noticia = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
  },
};
</script>

<style scoped></style>
